const _move = (obj, _attachList) => {
  for (var n in obj) {
    if (obj.hasOwnProperty(n)) {
      for (var k = 0; k < _attachList.length; ++k) {
        if (_attachList[k].list.indexOf(n) != -1) {
          _attachList[k].attach[n] = obj[n];
        }
      }
    }
  }
}

export default _move;