//@flow

import {
  DiscountType,
  Userinfo,
  PlanIDType,
  Subscription
} from "../../web-shared/dataTypes";

// import {
//   ADD_STORAGE_1COUNT_PLAN_ID, 
//   ADD_STORAGE_4COUNT_PLAN_ID,
//   MB_PER_STORAGE_COUNT
// } from "./plansInfo";

import {
  rcookie,
  setcookie,
  hrefSearch,
  __,
  renderQuota
} from "../../web-shared/utils";

import {
  PREMIUM_PLAN_ID,
  PREMIUM_PLUS_PLAN_ID,
  CUSTOM4TB_PLAN_ID,
  FAMILY2TB_PLAN_ID,
  FAMILY_CUSTOM_PLAN_ID,
  FAMILY_CUSTOM_2COUNT_PLAN_ID,
  FAMILY_CUSTOM_5COUNT_PLAN_ID,
  FAMILY_ADD_1COUNT_PLAN_ID,
  FAMILY_ADD_2COUNT_PLAN_ID,
  FAMILY_ADD_5COUNT_PLAN_ID,
  CUSTOM_PLAN_ID,
  ADD_STORAGE_1COUNT_PLAN_ID,
  ADD_STORAGE_4COUNT_PLAN_ID,
  ADD_STORAGE_8COUNT_PLAN_ID,
  ADD_STORAGE_12COUNT_PLAN_ID,
  ADD_STORAGE_20COUNT_PLAN_ID,
  CUSTOM_20COUNT_PLAN_ID,
  CRYPTO_PLAN_ID,
  EFH_PLAN_ID,
  ADDITIONAL_TRAFFIC_ID,
  MAX_CUSTOM_STORAGE_COUNT,
  MAX_FAMILY_STORAGE_COUNT,
  PASS_PREMIUM_PLAN_ID,
  PASS_FAMILY_PLAN_ID,
} from "../../web-shared/constants";

export {
  rcookie,
  setcookie,
  hrefSearch,
  __,
  renderQuota
};

export const removeHrefSearch = (params: Array<string>, forceRefresh?: boolean) => {
  if (params && typeof params == 'string') {
    params = [params];
  }

  if (params && params.length && location.search.length) {
    var currentQueriesArr = location.search.substring(1).split('&');
    var currentQueriesMap = {};
    var finalQueriesArr = [];
    var finalURL;

    // Create object map with all current search queries
    for (var i in currentQueriesArr) {
      currentQueriesMap[currentQueriesArr[i].split('=').shift()] = currentQueriesArr[i].split('=').pop();
    }

    // Delete requested queries
    for (var i in params) {
      if (params[i].split('=').shift() in currentQueriesMap) {
        delete currentQueriesMap[params[i].split('=').shift()];
      }
    }

    if (Object.keys(currentQueriesMap).length) {
      for (var i in currentQueriesMap) {
        finalQueriesArr.push(i + '=' + currentQueriesMap[i]);
      }

      finalURL = location.origin + location.pathname + '?' + finalQueriesArr.join('&');
    } else {
      finalURL = location.origin + location.pathname;
    }

    if (forceRefresh) {
      location = finalURL;
    } else {
      window.history.pushState({}, null, finalURL);
    }
  }
};

export const addHrefSearch = (params: Array<string> | string, forceRefresh: boolean, newLocationUrl?: string) => {
  if (!params) {
    return;
  }

  let finalURL;

  if (typeof params == 'string') {
    params = [params];
  }

  if (location.search.length) {
    let oldQueriesArr = location.search.substring(1).split('&');
    let oldQueriesMap = {};
    let givenQueriesMap = {};
    let finalQueriesMap = {};
    let finalQueriesArr = [];

    // Create object map with all current search queries
    for (let i in oldQueriesArr) {
      oldQueriesMap[oldQueriesArr[i].split('=').shift()] = oldQueriesArr[i].split('=').pop();
    }

    for (let i in params) {
      givenQueriesMap[params[i].split('=').shift()] = params[i].split('=').pop();

      // Delete queries which will be duplicated in the end and remove them
      if (params[i].split('=').shift() in oldQueriesMap) {
        delete oldQueriesMap[params[i].split('=').shift()];
      }
    }

    finalQueriesMap = Object.assign(oldQueriesMap, givenQueriesMap);

    for (let i in finalQueriesMap) {
      finalQueriesArr.push(i + '=' + finalQueriesMap[i]);
    }

    finalURL = (newLocationUrl ? newLocationUrl : (location.origin + location.pathname)) + '?' + finalQueriesArr.join('&');

  } else {
    // Directly append newly provided params
    finalURL = (newLocationUrl ? newLocationUrl : location.href) + '?' + params.join('&');
  }

  if (forceRefresh) {
    location = finalURL;
  } else {
    if (!window.history.state || window.history.state.page != finalURL) {
      window.history.pushState({ "page": finalURL }, null, finalURL);
    }
  }
};

export const saveToSessionstorage = (label: string, value: any) => {
  if (
    typeof Storage === "undefined" ||
    typeof sessionStorage === "undefined" ||
    sessionStorage === null
  ) {
    setcookie(label, value, 0);
  } else {
    sessionStorage.setItem(label, value);
  }
};

export const signToCurrency = {
  "$": "USD",
  "€": "EUR",
  "₤": "GBP",
  "BGN": "BGN"
};

export const currencyMap = {
  'USD': '$',
  'EUR': '€',
  'GBP': '₤',
  // Used for Vivacom plans only
  'BGN': 'BGN'
};

const _repl = (str: string, repl: {} = {}) => {
  str = _properSpaces(str);

  if (!obLength(repl)) {
    return str;
  }

  for (var n in repl) {
    str = str.replace(new RegExp("%" + n + "%", "g"), repl[n]);
  }

  return str;
};

const _properSpaces = str => {
  return str.split(String.fromCharCode(160)).join(" ");
};

export const obLength = (obj: {}) => {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      size++;
    }
  }
  return size;
};

type getDisabledPlanReasonParams = {
  user: Userinfo,
  forPlan: PlanIDType,
  forPeriod: "trial" | "month" | "year" | "lifetime",
  downgradeAllowed?: boolean,
  add500GBAllowed?: boolean,
  trafficCount?: number,
  maxCustomStorageCount?: number
};

export const getDisabledPlanReason = ({
  user = {},
  forPlan,
  forPeriod,
  downgradeAllowed,
  add500GBAllowed,
  trafficCount,
  canAddStorage,
  maxCustomStorageCount = MAX_CUSTOM_STORAGE_COUNT,
  maxFamilyStorageCount = MAX_FAMILY_STORAGE_COUNT
}: getDisabledPlanReasonParams) => {
  if (!user || !forPlan || !forPeriod) {
    return "";
  }

  const {
    business,
    lastsubscription,
    cryptolifetime,
    plan,
    premium,
    premiumlifetime,
    premiumexpires,
    cryptosubscription,
    efh,
    planparams,
    additionaltraffic,
    additionaltrafficlifetime,
    family,
    passmanagerdata,
    usepassmanagertrial: isfreetrialused
  } = user;

  const hasUserInfo = Object.keys(user).length > 0
  let islifetime;
  let isfreetrialactive;
  let passplan;
  let passpremiumexpire;
  let passFamily;

  if (passmanagerdata) {
    islifetime = passmanagerdata.islifetime
    isfreetrialactive = passmanagerdata.isfreetrialactive;
    passplan = passmanagerdata.plan;
    passpremiumexpire = passmanagerdata.premiumexpires;
    passFamily = passmanagerdata.family;
  }

  if (hasUserInfo) {
    // const {
    //   islifetime,
    //   istrial: isfreetrialactive,
    //   plan: passplan,
    //   planexpires: passpremiumexpire
    // } = passmanagerdata

    if (business) {
      return ("business");
    } else {
      let hasStorageSubscription = premium && !premiumlifetime;
      let hasCryptoSubscription = cryptosubscription;
      let hasSubscriptionPeriod = false;
      let isCanceled = lastsubscription && lastsubscription.status === "canceled";
      let incStorage = 0;
      if (!isCanceled && lastsubscription) {
        hasSubscriptionPeriod = lastsubscription.period;
      }

      const isCustomPlanId = forPlan === ADD_STORAGE_1COUNT_PLAN_ID || forPlan === ADD_STORAGE_4COUNT_PLAN_ID || forPlan === ADD_STORAGE_8COUNT_PLAN_ID || forPlan === ADD_STORAGE_12COUNT_PLAN_ID || forPlan === ADD_STORAGE_20COUNT_PLAN_ID || forPlan === CUSTOM_20COUNT_PLAN_ID;
      const isFamilyPlanId = forPlan === FAMILY_ADD_1COUNT_PLAN_ID || forPlan === FAMILY_ADD_2COUNT_PLAN_ID || forPlan === FAMILY_ADD_5COUNT_PLAN_ID || forPlan === FAMILY_CUSTOM_2COUNT_PLAN_ID || forPlan === FAMILY_CUSTOM_5COUNT_PLAN_ID;

      if (isCustomPlanId || isFamilyPlanId) {
        const parts = forPlan.split("_");
        incStorage = Number(parts[1]);
        forPlan = Number(parts[0]);
      }

      if ((!lastsubscription || (hasSubscriptionPeriod && lastsubscription.products.indexOf(forPlan) === -1) && !downgradeAllowed) && forPeriod != "lifetime") {
        if (hasStorageSubscription && plan) {
          if (plan == forPlan && hasStorageSubscription == forPeriod) {
            return ("current_plan");
          } else if (plan > forPlan) {
            return ("lower_plan");
          }
        } else if (efh && forPlan === EFH_PLAN_ID) {
          return ("current_plan");
        } else if (additionaltraffic && forPlan == ADDITIONAL_TRAFFIC_ID) {
          if (!additionaltrafficlifetime && planparams.additionaltraffic == trafficCount) {
            return ("current_plan");
          } else if (planparams.additionaltraffic > trafficCount) {
            return ("traffic_downgrade_restriction");
          }
        }
      } else if (!lastsubscription && forPeriod === "lifetime" && hasStorageSubscription && plan && plan > forPlan) {
        return ("lower_plan");
      } else if (plan && hasStorageSubscription && hasSubscriptionPeriod && lastsubscription.products.indexOf(plan) === -1 && forPeriod != "lifetime" && !downgradeAllowed) {
        if (forPlan == plan) {
          return ("current_plan");
        } else if (plan > forPlan) {
          return ("lower_plan");
        }
      }

      // Buying Crypto
      if (forPlan == CRYPTO_PLAN_ID) {
        if (cryptolifetime) {
          return ("crypto_lifetime");
        } else if (hasSubscriptionPeriod && !hasCryptoSubscription) {
          if (hasCryptoSubscription == "month" && forPeriod == "month") {
            return ("current_plan");
          } else if (hasSubscriptionPeriod == "year" && forPeriod == "month") {
            return ("lower_period");
          }
        } else if (
          hasCryptoSubscription &&
          hasSubscriptionPeriod &&
          isCanceled == false
        ) {
          if (hasSubscriptionPeriod == "month" && forPeriod == "month") {
            return ("current_plan");
          } else if (hasSubscriptionPeriod == "year") {
            if (forPeriod == "month") {
              return ("lower_period");
            } else if (forPeriod == "year") {
              return ("current_plan");
            }
          }
        }
      } else if (forPlan == EFH_PLAN_ID) {
        if (efh) {
          if (hasSubscriptionPeriod == forPeriod) {
            return ("current_plan");
          } else if (hasSubscriptionPeriod == "year") {
            if (forPeriod == "month") {
              return ("lower_period");
            }
          }
        }
        // Buying Traffic
      } else if (forPlan == ADDITIONAL_TRAFFIC_ID) {
        if (plan === CUSTOM4TB_PLAN_ID) {
          return ("custom_subscription");
        } else if (forPeriod === "lifetime" && !premiumlifetime) {
          return "traffic_lifetime_restriction";
        } else if (plan == FAMILY2TB_PLAN_ID || plan == FAMILY_CUSTOM_PLAN_ID) {
          return "custom_subscription";
        } else if (forPeriod !== "lifetime" && additionaltrafficlifetime) {
          return "traffic_lifetime_restriction_subs";
        } else if (forPeriod === "month" && hasSubscriptionPeriod == "year" && (!downgradeAllowed || lastsubscription.billingprovider === 11)) {
          return ("traffic_downgrade_restriction");
        } else if (additionaltraffic && !additionaltrafficlifetime && (forPeriod === "month" || forPeriod === "year") && planparams.additionaltraffic == trafficCount && forPeriod === hasSubscriptionPeriod) {
          return ("current_plan");
        } else if (additionaltrafficlifetime && forPeriod === "lifetime" && planparams.additionaltraffic == trafficCount) {
          return ("current_plan");
        } else if (additionaltraffic && planparams.additionaltraffic > trafficCount && ((forPeriod === hasSubscriptionPeriod && !downgradeAllowed) || (forPeriod === "lifetime" && additionaltrafficlifetime))) {
          return ("traffic_lifetime_restriction_subs");
        }
        // Buying Pass
      } else if (forPlan == PASS_PREMIUM_PLAN_ID) {
        if ((isfreetrialactive && forPeriod === "trial") || (islifetime && forPeriod === "lifetime" && passplan === 1)) {
          return ("current_plan");
        } else if (passmanagerdata && forPeriod === "free") {
          return ("current_active_plan");
        } else if (isfreetrialused && forPeriod === 'trial') {
          return "plan_upgrade_restrictions";
        } else if ((islifetime && forPeriod != "lifetime") || passplan === 2) {
          return ("custom_subscription");
          // } else if (!emailverified) {
          //   return ("verify_email")
        } else if (lastsubscription && !isCanceled) {
          if (lastsubscription.planid) {
            let activePlanId = lastsubscription.planid;
            let activePlanPeriod = lastsubscription.period;
            if (activePlanId === forPlan) {
              if (activePlanPeriod === forPeriod) {
                return "current_plan";
              } else if (activePlanPeriod === 'month' && (forPeriod === 'trial' || forPeriod === 'free')) {
                return "plan_upgrade_restrictions";
              } else if (activePlanPeriod === 'year' && (forPeriod === 'trial' || forPeriod === 'free' || forPeriod === 'month')) {
                return "plan_upgrade_restrictions";
              }
            }

          } else if (lastsubscription.products) {
            let products = lastsubscription.products;
            let activePlanPeriod = lastsubscription.period;
            if (products.indexOf(PASS_PREMIUM_PLAN_ID) !== -1) {
              if (activePlanPeriod === forPeriod) {
                return "current_plan";
              } else if (activePlanPeriod === 'month' && (forPeriod === 'trial' || forPeriod === 'free')) {
                return "plan_upgrade_restrictions";
              } else if (activePlanPeriod === 'year' && (forPeriod === 'trial' || forPeriod === 'free' || forPeriod === 'month')) {
                return "plan_upgrade_restrictions";
              }
            }
          }
        } else if ((!lastsubscription || (lastsubscription && isCanceled)) && passplan == 1 && !isfreetrialactive) {
          if (islifetime || (!islifetime && forPeriod != "lifetime")) {
            return "plan_upgrade_restrictions";
          }
        }

        // Active Pass Lifetime plan = disable any other Pass Plans.
        if (!isfreetrialactive && (passplan === 1 || passplan === 2) && islifetime) {
          return "plan_upgrade_restrictions";
        }
      } else if (forPlan == PASS_FAMILY_PLAN_ID) {
        if (passplan === 2 && passFamily && !passFamily.owner) {
          return "custom_subscription";
        };

        if ((isfreetrialactive && forPeriod === "trial") || (islifetime && forPeriod === "lifetime" && passplan === 2)) {
          return "current_plan";
        }

        if (lastsubscription && !isCanceled) {
          let activePlanPeriod = lastsubscription.period;

          if (activePlanPeriod === 'year' && forPeriod === 'month') {
            return "plan_upgrade_restrictions";
          }

          if (lastsubscription.planid) {
            let activePlanId = lastsubscription.planid;

            if (activePlanId === forPlan && activePlanPeriod === forPeriod) {
              return "current_plan";
            }
          } else if (lastsubscription.products) {
            let products = lastsubscription.products;

            if (products.indexOf(forPlan) !== -1 && activePlanPeriod === forPeriod) {
              return "current_plan";
            }
          }
        }

        // Active Pass Lifetime plan = disable any other Pass Plans.
        if (!isfreetrialactive && (passplan === 1 || passplan === 2) && islifetime) {
          return "plan_upgrade_restrictions";
        }

        // Buying Storage
      } else {
        if (plan == PREMIUM_PLAN_ID || plan == PREMIUM_PLUS_PLAN_ID || plan == CUSTOM4TB_PLAN_ID) {
          if (premiumlifetime) {
            if (plan == CUSTOM4TB_PLAN_ID && typeof planparams != 'undefined' && planparams.storage > 1 && canAddStorage) {
              return ("custom_plan_upgrade_exceeded");
            } else if (forPlan == CUSTOM_PLAN_ID && canAddStorage) {
              if ((incStorage == maxCustomStorageCount && (plan == PREMIUM_PLAN_ID || plan == CUSTOM4TB_PLAN_ID)) || (incStorage > maxCustomStorageCount && plan == PREMIUM_PLAN_ID)) {
                return ("plan_upgrade_restrictions");
              } else if (incStorage == 1 && !add500GBAllowed) {
                return ((plan == PREMIUM_PLUS_PLAN_ID || plan == CUSTOM4TB_PLAN_ID) ? "plan_upgrade_restrictions" : "custom_plan_upgrade_restrictions");
              }
            } else if (forPlan <= plan || forPlan == FAMILY2TB_PLAN_ID || forPlan == FAMILY_CUSTOM_PLAN_ID || (forPlan == CUSTOM_PLAN_ID && !canAddStorage)) {
              return ("storage_lifetime");
            }
          } else if (hasSubscriptionPeriod && isCanceled == false) {
            if (forPeriod == "lifetime") {
              if (forPlan < plan) {
                return ("lower_plan");
              }
            } else if (hasSubscriptionPeriod == "month") {
              if (forPeriod == "month") {
                if (forPlan < plan && !downgradeAllowed) {
                  return ("lower_plan");
                } else if (forPlan == plan) {
                  return ("current_plan");
                }
              } else if (
                (forPeriod == "year" && forPlan < plan) ||
                (forPlan < plan && premiumexpires)
              ) {
                return ("lower_plan");
              }
            } else if (hasSubscriptionPeriod == "year") {
              if (forPeriod == "month" && !downgradeAllowed) {
                return ("lower_period");
              } else {
                if (forPlan < plan && !downgradeAllowed) {
                  return ("lower_plan");
                } else if (forPlan == plan) {
                  if (!downgradeAllowed || hasSubscriptionPeriod == forPeriod) {
                    return ("current_plan");
                  }
                }
              }
            }
          } else if (isCanceled == false) {
            if (forPlan < plan) {
              return ("lower_plan");
            } else if (forPlan <= plan && premiumexpires) {
              let now = new Date();
              let expires = new Date(premiumexpires);
              //Get 1 day in milliseconds
              let oneDay = 1000 * 60 * 60 * 24;
              let daysLeft = Number(expires) - Number(now) / oneDay;

              let monthlyTabisActive = forPeriod === "month";
              let annualTabisActive = forPeriod === "year";

              if (daysLeft > 10 && (annualTabisActive || monthlyTabisActive)) {
                return ("lower_period");
              }
            }
          }
        } else if (plan == CUSTOM_PLAN_ID) {
          if (typeof planparams != 'undefined' && planparams.storage >= maxCustomStorageCount && forPlan == CUSTOM_PLAN_ID && canAddStorage) {
            return ("custom_plan_upgrade_exceeded");
          } else if (typeof planparams != 'undefined' && ((planparams.storage + incStorage) > maxCustomStorageCount) && forPlan == CUSTOM_PLAN_ID && canAddStorage) {
            return ("plan_upgrade_restrictions");
          } else if (forPlan == CUSTOM_PLAN_ID && incStorage == 1 && !add500GBAllowed) {
            if (typeof planparams != 'undefined' && planparams.storage <= 3) {
              return ("custom_plan_upgrade_restrictions");
            } else {
              return ("plan_upgrade_restrictions");
            }
          } else if (forPlan < plan || (!canAddStorage && forPlan == CUSTOM_PLAN_ID)) {
            return ("storage_lifetime");
          } else if (forPeriod === "year" || forPeriod === "month") {
            return ("storage_lifetime");
          }
        } else if ((plan == FAMILY2TB_PLAN_ID || plan == FAMILY_CUSTOM_PLAN_ID) && forPlan !== FAMILY_CUSTOM_PLAN_ID) {
          return ("family");
        } else if (forPlan == FAMILY_CUSTOM_PLAN_ID || forPlan == FAMILY2TB_PLAN_ID) {
          if ((plan == FAMILY_CUSTOM_PLAN_ID || plan == FAMILY2TB_PLAN_ID) && family && !family.owner) {
            return "family";
          } else if (plan == FAMILY_CUSTOM_PLAN_ID && typeof planparams != 'undefined') {
            if (planparams.storage >= maxFamilyStorageCount && canAddStorage) {
              return ("custom_plan_upgrade_exceeded");
            } else if (((planparams.storage + incStorage) > maxFamilyStorageCount) && canAddStorage) {
              return ("plan_upgrade_restrictions");
            }
          }
        } else {
          if (forPlan == CUSTOM_PLAN_ID && incStorage > maxCustomStorageCount) {
            return ("plan_upgrade_restrictions");
          }
        }
      }
    }
  }
};

export const preventEnteringNumbers = (event) => {
  const key = event.which || event.keyCode || event.charCode;
  const pressedCharacter = String.fromCharCode(key);

  if (!(/[a-zA-Z '-]/).test(pressedCharacter)) {
    event.preventDefault();
  }
}

type creditCardValidation = {
  name: string,
  range: string,
  valid_length: Array<string>
}

export const findCardType = (cardNumber: string): creditCardValidation => {
  const creditCardValidations = [
    {
      name: "amex",
      range: "34, 37",
      valid_length: ["15"]
    },
    {
      name: "diners",
      range: "301, 302, 303, 304, 305, 36",
      valid_length: ["16"]
    },
    {
      name: "jcb",
      range: "352, 353, 354, 355, 356, 357, 358",
      valid_length: ["16"]
    },
    {
      name: "visa",
      range: "4",
      valid_length: ["19", "18", "17", "16", "15", "14", "13"]
    },
    {
      name: "mastercard",
      range: "50, 51, 52, 53, 54, 55, 222",
      valid_length: ["16"]
    },
    {
      name: "discover",
      range: "6011, 622, 644, 645, 646, 647, 648, 649",
      valid_length: ["16"]
    }
  ];

  return creditCardValidations.find((cardTemplate: creditCardValidation): (creditCardValidation | false) => {
    let isThereAMatch = cardTemplate.range.split(", ").some((range: string) => {
      const rangeLength = range.length;
      return range === cardNumber.slice(0, rangeLength);
    });
    return isThereAMatch;
  });
}

export const getExpiryDatePeriod = (expiryDate: string, period: string): string => {
  const periods = {
    month: Number(expiryDate.split("/")[0]),
    year: Number(expiryDate.split("/")[1])
  }

  if (periods.year > 2000 && periods.year < 2100) {
    periods.year -= 2000;
  }

  return periods[period];
}

export const validateExpiryDate = (expiryDate: string): boolean => {
  const month = getExpiryDatePeriod(expiryDate, "month");
  const year = getExpiryDatePeriod(expiryDate, "year");
  const currentYear = new Date().getFullYear() - 2000;
  const currentMonth = new Date().getMonth() + 1;

  const validateExpression =
    month <= 12 &&
    ((month > 0 && year > currentYear) || (month >= currentMonth && year == currentYear)) &&
    year <= 40;

  return validateExpression;
};


export const getDeviceID = () => {
  if (!window.localStorage.getItem("deviceid") && !rcookie("deviceid")) {
    return rcookie("deviceid");
  } else if (rcookie("deviceid")) {
    if (window.localStorage.getItem("deviceid")) {
      window.localStorage.removeItem("deviceid");
    }
    return rcookie("deviceid");
  } else if (window.localStorage.getItem("deviceid")) {
    setcookie("deviceid", window.localStorage.getItem("deviceid"), 365);
    window.localStorage.removeItem("deviceid");
    return rcookie("deviceid");
  }
};

export const getCurrency = ({ auth, callback = () => { } }: { auth: string, callback: () => void }) => {
  const params = {};
  let getCurrencyTries = 0;

  if (getCurrencyTries >= 5) {
    onSuccess("$");
    return;
  }

  if (auth) {
    params["auth"] = $.cookie("pcauth");
  }

  if (rcookie("forcecurrency")) {
    params["currency"] = $.cookie("forcecurrency");
  }

  if (rcookie("forcecountry")) {
    params["country"] = $.cookie("forcecountry");
  }

  const onSuccess = (currency) => {
    if (typeof Storage !== "undefined" && currencyMap[currency]) {
      sessionStorage.setItem("pclCurrency", currencyMap[currency]);
    }

    callback(currency);
  };

  const getCurrencyApiCall = () => {
    getCurrencyTries++;
    const onError = () => {
      setTimeout(() => {
        getCurrencyApiCall();
      }, 300);
    };

    pCloudGlobals.apiMethod && pCloudGlobals.apiMethod(
      "getcurrency",
      params,
      (data) => {
        if (data.currency) {
          onSuccess(data.currency);
        } else {
          onError();
        }
      },
      {
        errorCallback: onError
      }
    );
  };

  getCurrencyApiCall();
};

export const areArraysEqual = (array1: Array<any>, array2: Array<any>): boolean => {
  if (!array1 || !array2) {
    return false;
  }

  if (array1.length !== array2.length) {
    return false;
  }

  for (let index1 = 0; index1 < array1.length; index1++) {
    const e1 = array1[index1];
    const e2 = array2[index1];

    if (Array.isArray(e1) && Array.isArray(e2)) {
      compareArrays(e1, e2);
    } else if (e1 !== e2) {
      return false;
    }
  }

  return true;
}

export const formatMS = (milliseconds: number, type: "day" | "hour" | "minute" | "second"): number => {
  const typeMap = {
    "day": { "divider": 86400000, "modulo": milliseconds + 1 },
    "hour": { "divider": 3600000, "modulo": 24 },
    "minute": { "divider": 60000, "modulo": 60 },
    "second": { "divider": 1000, "modulo": 60 }
  };

  if (!(type in typeMap)) {
    console.error("Invalid duration type: " + type);

    return 0;
  }

  return parseInt((milliseconds / typeMap[type].divider) % typeMap[type].modulo);
};

export const msToTime = (duration: number, showHours = true): string => {
  let seconds = parseInt((duration / 1000) % 60);
  let minutes = parseInt((duration / 60000) % 60);
  let hours = parseInt((duration / 3600000) % 24);

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return (showHours ? hours + ':' : '') + minutes + ':' + seconds;
};

export const cleanFloat = (number: number): number => {
  let value = Number(number);
  let decimal = value % 1 == 0 ? 0 : 2;
  let result = Number(value.toFixed(decimal));

  if (result % 1 == 0) {
    decimal = 0;
  }

  return Number(value.toFixed(decimal));
};

export const browserVersion = (userAgent, regex) => {
  return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
}

export const detectBrowser = (version) => {
  const userAgent = navigator.userAgent;
  let browser = "unkown";
  // Detect browser name
  browser = (/ucbrowser/i).test(userAgent) ? 'UCBrowser' : browser;
  browser = (/edg/i).test(userAgent) ? 'Edge' : browser;
  browser = (/googlebot/i).test(userAgent) ? 'GoogleBot' : browser;
  browser = (/chromium/i).test(userAgent) ? 'Chromium' : browser;
  browser = (/firefox|fxios/i).test(userAgent) && !(/seamonkey/i).test(userAgent) ? 'Firefox' : browser;
  browser = (/; msie|trident/i).test(userAgent) && !(/ucbrowser/i).test(userAgent) ? 'IE' : browser;
  browser = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent) ? 'Chrome' : browser;;
  browser = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent) ? 'Safari' : browser;
  browser = (/opr|opera/i).test(userAgent) ? 'Opera' : browser;
  browser = navigator.brave ? "Brave" : browser;

  if (version) {
    switch (browser) {
      case 'UCBrowser': return `${browser}/${browserVersion(userAgent, /(ucbrowser)\/([\d\.]+)/i)}`;
      case 'Edge': return `${browser}/${browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
      case 'GoogleBot': return `${browser}/${browserVersion(userAgent, /(googlebot)\/([\d\.]+)/i)}`;
      case 'Chromium': return `${browser}/${browserVersion(userAgent, /(chromium)\/([\d\.]+)/i)}`;
      case 'Firefox': return `${browser}/${browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i)}`;
      case 'Chrome': return `${browser}/${browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i)}`;
      case 'Safari': return `${browser}/${browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
      case 'Opera': return `${browser}/${browserVersion(userAgent, /(opera|opr)\/([\d\.]+)/i)}`;
      case 'IE': const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
        // IE version is mapped using trident version 
        // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
        return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
      default: return `unknown/0.0.0.0`;
    }
  }

  return browser;
}

export const isValidEmailAddress = (email: string): boolean => {
  const pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\	".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return pattern.test(email);
};

export const apiCall = (method, params, onSuccess, onError, opts) => {
  const dfd = jQuery.Deferred();
  opts = {
    errorCallback: (data) => {
      dfd.reject(data);
      if (typeof onError === 'function') {
        onError(data);
      }
    },
    ...opts
  }

  pCloudGlobals.apiMethod(
    method,
    params,
    (data) => {
      if (typeof onSuccess === 'function') {
        onSuccess(data);
      }
      dfd.resolve(data);
    },
    opts
  );

  return dfd.promise();
};

export const apiCallPost = (method, params, onSuccess, onError, opts) => {
  const dfd = jQuery.Deferred();
  opts = {
    type: "POST",
    errorCallback: (data) => {
      dfd.reject(data);
      if (typeof onError === 'function') {
        onError(data);
      }
    },
    ...opts
  }

  pCloudGlobals.apiMethod(
    method,
    params,
    (data) => {
      dfd.resolve(data);
      if (typeof onSuccess === 'function') {
        onSuccess(data);
      }
    },
    opts
  );

  return dfd.promise();
};

export const detectIsMobile = () => {
  return /Mobile|mini|Fennec|Android|iP(od|hone)/.test(navigator.appVersion);
};

export const onMouseOut = (event, callback) => {
  const e = event ? event : window.event;
  // If this is an autocomplete element.
  if (e.target && e.target.tagName && e.target.tagName.toLowerCase() == "input") {
    return;
  }

  // Get the current viewport width.
  const vpWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  // If the current mouse X position is within 50px of the right edge of the viewport, return.
  if (e.clientX >= (vpWidth - 50)) {
    return;
  }

  // If the current mouse Y position is not within 50px of the top edge of the viewport, return.
  if (e.clientY >= 50) {
    return;
  }

  // Fixes mozila problem where event is getting triggered on rigth click. -83 seems to be a constant value for clientY in that case.
  if (e.clientY === -83) {
    return;
  }

  // Reliable, works on mouse exiting window and user switching active program
  const from = e.relatedTarget || e.toElement;

  // User is exiting window
  if (!from && callback) {
    callback();
  }
}

export const isInternetExplorer = () => {
  return !!document.documentMode;
}

export const scrollToTop = () => {
  window.scrollTo(0);
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return null;
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i == 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(0) + ' ' + sizes[i];
};

export const setTitleByCodeUrl = (codeUrl, setTitle, setRegisterTitle, setLoginTitle) => {
  if (codeUrl) {
    if (codeUrl == 'DROPBOX2PCLOUD') {
      setTitle('Special for Dropbox users - press submit and enjoy 3 months of Premium 500 GB for Free');
    } else if (codeUrl == 'HUBIC2PCLOUD') {
      setTitle('Special for hubiC users - press submit and enjoy 3 months of Premium 500 GB for Free');
    } else if (codeUrl == 'APPO') {
      setTitle('Special for"APPO" members - register now and enjoy 3 months of Premium 500 GB for Free');
    } else if (codeUrl == 'BIRTHDAY4U') {
      setTitle(__("PromoPage_birthday_text"));
    } else if (codeUrl == 'PCLOUDMUSIC') {
      setTitle(__("pCloud_Music_coupon_submit").replace("%url_promo%", codeUrl));
      setRegisterTitle(__("pCloud_Music_coupon_register").replace("%url_promo%", codeUrl));
      setLoginTitle(__("pCloud_Music_coupon_login").replace("%url_promo%", codeUrl));
    } else if (codeUrl == 'BDAY3MONTHS') {
      setTitle(__("pm_coupon_submit").replace("%url_promo%", codeUrl).replace("%months%", 3));
      setRegisterTitle(__("pm_coupon_register").replace("%url_promo%", codeUrl).replace("%months%", 3));
      setLoginTitle(__("pm_coupon_login").replace("%url_promo%", codeUrl).replace("%months%", 3));
    } else if (codeUrl == 'PM6MONTHS' || codeUrl == "INT6MONTHS"|| codeUrl == "LFS6MONTHS") {
      setTitle(__("pm_coupon_submit").replace("%url_promo%", codeUrl).replace("%months%", 6));
      setRegisterTitle(__("pm_coupon_register").replace("%url_promo%", codeUrl).replace("%months%", 6));
      setLoginTitle(__("pm_coupon_login").replace("%url_promo%", codeUrl).replace("%months%", 6));
    } else {
      setTitle(__("promo_submit_with_url").replace("%url_promo%", codeUrl));
      setRegisterTitle(__("Register for Free and enter your promo code. It will become effective immediately!"));
      setLoginTitle(__("Enter your promo code upon Login and it will become effective immediately."));
    }
  }
  else {
    setTitle(__('Have a promo code? Enter it below.'));
    setRegisterTitle(__("Register for Free and enter your promo code. It will become effective immediately!"));
    setLoginTitle(__("Enter your promo code upon Login and it will become effective immediately."));
  }
};

export const getMonthsFromCode = (code) => {
  if (!code) {
    return ""
  };

  const indexPosition = code.indexOf("MONTHS");
  let monthsCountIndex;

  if (indexPosition === -1) {
    return "";
  }

  if (indexPosition > 0) {
    monthsCountIndex = indexPosition - 1;
  } else {
    monthsCountIndex = indexPosition + 7;
  }

  if (!isNaN(code[monthsCountIndex])) {
    return code[monthsCountIndex]
  }

  return "";
};

export const getLanguage = () => {
  return currentLang || $.cookie('set_lang') || $.cookie('lang') || 'en'
}

export const countriesInEuropeList = {
  "AT": "Austria",
  "BE": "Belgium",
  "BG": "Bulgaria",
  "HR": "Croatia",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "EE": "Estonia",
  "FI": "Finland",
  "FR": "France",
  "DE": "Germany",
  "GR": "Greece",
  "HU": "Hungary",
  "IE": "Ireland",
  "IT": "Italy",
  "LV": "Latvia",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MT": "Malta",
  "NL": "Netherlands",
  "PL": "Poland",
  "PT": "Portugal",
  "RO": "Romania",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "ES": "Spain",
  "SE": "Sweden",
  "CH": "Switzerland",
  "GB": "United Kingdom",
  "AL": "Albania",
  "AD": "Andorra",
  "AM": "Armenia",
  "BY": "Belarus",
  "BA": "Bosnia and Herzegovina",
  "FO": "Faroe Islands",
  "GE": "Georgia",
  "GI": "Gibraltar",
  "IS": "Iceland",
  "IM": "Isle of Man",
  "XK": "Kosovo",
  "LI": "Liechtenstein",
  "MK": "Macedonia",
  "MD": "Moldova",
  "MC": "Monaco",
  "ME": "Montenegro",
  "NO": "Norway",
  "RU": "Russian Federation",
  "SM": "San Marino",
  "RS": "Serbia",
  "CH": "Switzerland",
  "TR": "Turkey",
  "UA": "Ukraine",
  "GB": "United Kingdom",
  "VA": "Vatican City State"
};

export const isCountryInEurope = (countryCodeToNameMap, country) => {
  return countryCodeToNameMap[country];
};

export const encodeQueryData = (data) => {
  const ret = [];

  for (let d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  }

  return ret.join('&');
};

export const calculateDiscountPercent = (price, oldPrice) => Math.floor((1 - (price/oldPrice)) * 100);

export const convertDateToDDMMMYY = (date: string, shortMonthName = true, dayMonthSeparator = " ", monthYearSeparator = " ") => {
  const monthNames = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec"
  ];
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = monthNames[dateObj.getMonth()];
  const monthKey = "month_" + (!shortMonthName ? "full_" : "") + month;
  const year = dateObj.getFullYear();

  return day + dayMonthSeparator + __(monthKey) + monthYearSeparator + year;
};

export const daysDiff = (dateTo: string, dateFrom: string = null) => {
  const dateFromObj = dateFrom !== null && dateFrom !== "" ? new Date(dateFrom) : new Date();
  const dateToObj = new Date(dateTo);

  // Change hours and minutes to be from the start of the day.
  dateFromObj.setHours(0,0,0,0);
  dateToObj.setHours(0,0,0,0);
  return (dateToObj.getTime() - dateFromObj.getTime() + ((dateFromObj.getTimezoneOffset() - dateToObj.getTimezoneOffset()) * 60 * 1000)) / (1000 * 3600 * 24);
};